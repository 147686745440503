import main from './main.jpg'
import './App.css';

function App() {
  return (
    <div className="App">
      <img src={main} className="App-logo" alt="logo" />
      <div className="footer">
        <p>
          <a href="https://beian.miit.gov.cn/">晋ICP备2021010651号-1</a>
          <a href="tel:+8618603516608">联系电话:18603516608</a>
          &copy;2021 山西完美四季供应链管理有限公司</p>
      </div>
    </div>
  );
}

export default App;
